import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { SEO } from '../components/SEO/SEO';

const BlogDetail = ({ data, location }) => {
  const blogItem = data.contentfulBlogPost;

  let image;
  let socialMediaImage;
  if (blogItem.heroImage) {
    image = getImage(blogItem.heroImage.gatsbyImageData);
    socialMediaImage = `https:${blogItem.heroImage.fluid.src}`;
  }

  return (
    <Layout>
      <SEO
        location={location}
        pageMetadata={{
          title: blogItem.title,
          description: blogItem.description.description,
          imageUrl: socialMediaImage,
        }}
      />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <figure>
              <GatsbyImage
                image={image}
                alt={blogItem.title || ''}
                class="w-full rounded-lg mb-8"
              />
            </figure>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <span className="sr-only">{blogItem.author.name}</span>
              </div>
              <div className="">
                <p className="text-sm font-medium text-primary">{blogItem.author.name}</p>
                {/* <div className="flex space-x-1 text-sm text-gray-500"> */}
                {/*  <time dateTime={blogItem.publishDate}>{blogItem.publishDate}</time> */}
                {/* </div> */}
              </div>
            </div>
            <h1 className="mt-6">
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {blogItem.title}
              </span>
            </h1>
          </div>
          <div
            className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"
            dangerouslySetInnerHTML={{
              __html: blogItem.body.childMarkdownRemark.html || '',
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetail;

export const query = graphql`
  query BlogPostDetail($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      id
      title
      description {
        description
      }
      slug
      publishDate(formatString: "DD MMMM, YYYY", locale: "nl")
      body {
        childMarkdownRemark {
          html
          excerpt
          longExcerpt: excerpt(pruneLength: 260)
        }
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH)
        fluid(maxWidth: 1600) {
          src
        }
      }
      author {
        name
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      category {
        id
        name
        slug
      }
    }
  }
`;
